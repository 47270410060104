<template>
    <div class="overview">
        <div class="head">
            <div class="logo" @click="route_to('/home')">
                <img src="../assets/ikotun_logo_2.png"/>
            </div>
            
            <div class="options">
                <div class="opt">
                    <search :size="'18px'" :style="{'height': '18px'}"/>
                </div>
                <router-link to="/notifications">
                    <div class="opt">
                        <notification :size="'18px'" :style="{'height': '18px'}"/>
                    </div>
                </router-link>
            </div>
        </div>

        <div class="banner">
            <div class="banner_img">
                <img src="../assets/svg/overview_banner.svg"/>
            </div>
            <div>
                <div class="title">Connecting Ikotun</div>
                <div class="text">
                    Bringing the presence of ikotun and it's environ to the internet
                </div>
            </div>
        </div>
        <div class="icons">
            <div @click="route_to('/home')">
                <div class="icon">
                    <home :size="'20px'"/>
                </div>
                <div class="label">Home</div>
            </div>

            <div @click="route_to('/message')">
                <div class="icon">
                    <chat :size="'20px'"/>
                </div>
                <div class="label">Chats</div>
            </div>

            <div @click="route_to('/city/about')">
                <div class="icon">
                    <about :size="'20px'"/>
                </div>
                <div class="label">About</div>
            </div>

            <div @click="route_to('/terms')">
                <div class="icon">
                    <terms :size="'20px'"/>
                </div>
                <div class="label">Terms</div>
            </div>
            
            <!-- <div class="">
                <div class="dp">
                    <img :src="$root.$data.dp"/>
                </div>
                <div class="label">Profile</div>
            </div> -->
        </div>

        <div class="market">
            <div class="market_img">
                <market :size="'50px'"/>
            </div>
            <div>
                <div class="market_title">Market Place</div>
                <div class="market_text">
                    Buy and sell on ikotun connect with the seemless integrated eccommerce
                </div>
            </div>
        </div>


        <div class="profile">
            <div class="profile_flex">
                <div class="dp">
                    <img :src="$root.$data.dp"/>
                </div>
                <div>
                    <div class="name">
                        {{ this.$root.$data.firstname }} {{ this.$root.$data.lastname }}
                    </div>
                    <div class="username">@{{ this.$root.$data.username }}</div>
                </div>
            </div>
            <div class="stat">
                <div class="flex">
                    <div class="l">Posts:</div>
                    <div class="value">{{ this.$root.$data.posts }}</div>
                </div>
                <div class="flex">
                    <div class="l">Comments:</div>
                    <div class="value">{{ this.$root.$data.comments }}</div>
                </div>
                <div class="flex">
                    <div class="l">Followers:</div>
                    <div class="value">{{ this.$root.$data.followers }}</div>
                </div>
            </div>
            <div class="profile_opts">
                <div class="profile_opt" @click="open_profile()">Profile</div>
                <div class="profile_opt" @click="logout()">Logout</div>
            </div>
        </div>

        <div class="version">
            Version {{ this.$root.$data.app_version }}
        </div>
    </div>
</template>

<script>
import Localbase from 'localbase'
let db = new Localbase('ikotun')

import search from '../icons/search.vue'
import notification from '../icons/notification.vue';

import home from '../icons/home.vue';
import chat from '../icons/chat.vue';
import about from '../icons/about.vue';
import terms from '../icons/terms.vue'

import market from '../icons/market.vue';

export default {
    components: {
        search, notification,
        home, chat, about, terms,
        market
    },
    data() {
        return {
        }
    },

    methods: {
        route_to(where) {
            this.$router.push(where)
        },
        
        open_profile() {
            let profile = {
                'created': this.$root.$data.created,
                'verified': this.$root.$data.verified,
                'username': this.$root.$data.username,
                'firstname': this.$root.$data.firstname,
                'lastname': this.$root.$data.lastname,
                'location': this.$root.$data.location,
                'dp': this.$root.$data.dp,
                'cover': this.$root.$data.cover,
                'posts': this.$root.$data.posts,
                'comments': this.$root.$data.comments,
                'followers': this.$root.$data.followers,
                'followings': this.$root.$data.followings
            }

            localStorage.setItem('profile', JSON.stringify(profile))

            this.$router.push('/profile/' + this.$root.$data.username)
        },

        logout() {
            this.$root.$data.login = false
            
            this.$http.defaults.headers.common['Auth'] = ''
            localStorage.clear()

            this.$socket.emit('logout')
            
            setTimeout(() => {
                
                db.delete().then(() => {
                    this.$router.push('/membership')
                    setTimeout(() => {
                        this.$router.go()
                    }, 1000);
                })
            }, 2000);
        }
    }
}
</script>

<style scoped>
    .overview {
        padding: 20px;
        box-sizing: border-box;
        background-color: white;
        height: 100vh;
        overflow: auto;

        position: relative;
    }
    .head {
        padding-bottom: 20px;
        display: flex;
        align-items: center;
        justify-content: space-between;
        background-color: white;
    }
    .logo img {
        width: 100px;
    }
    
    .options {
        display: flex;
        align-items: center;
        gap: 15px;
    }
    .opt {
        width: 35px;
        height: 35px;
        border-radius: 50%;
        display: flex;
        align-items: center;
        justify-content: center;
        background-color: var(--trans);
        fill: var(--main);
    }



    .banner {
        /* background-color: #edeef0; */
        /* background: linear-gradient(to left, tomato, rgb(255, 255, 255)); */
        border-radius: 10px;
        display: flex;
        align-items: center;
        gap: 20px;
        padding: 20px;
        /* color: white; */
        border: 1px solid #ddd;
    }
    .banner_img img {
        width: 70px;
    }
    .title {
        font-size: 16px;
        font-weight: 800;
    }
    .text {
        font-size: 12px;
        color: gray;
        margin-top: 5px;
    }



    

    .icons {
        display: flex;
        justify-content: space-around;
        /* padding-top: 20px; */
        margin: 20px -20px;
    }
    .icon {
        text-align: center;
        /* padding: 20px 0px; */
        fill: var(--main);
        margin: 0 auto;
        width: 40px;
        height: 40px;
        border-radius: 50%;
        border: 1px solid #ddd;
        display: flex;
        align-items: center;
        justify-content: center;
        background-color: var(--trans);
        transition: .3s;
    }
    .icon:active {
        background-color: var(--main);
        color: var(--main);
        fill: white;
    }
    .label {
        font-size: 12px;
        font-weight: 500;
        margin-top: 3px;
        text-align: center;
        /* color: gray; */
    }



    .market {
        background: linear-gradient(to right, var(--main), tomato);
        border-radius: 5px;
        display: flex;
        align-items: center;
        gap: 20px;
        padding: 20px;
        color: white;
        fill: white;
    }
    .market_title {
        font-size: 16px;
        font-weight: 800;
    }
    .market_text {
        font-size: 12px;
        opacity: .8;
        margin-top: 5px;
    }


    .profile {
        border: 1px solid #ddd;
        border-radius: 5px;
        margin-top: 20px;
        overflow: hidden;
    }
    .profile_flex {
        display: flex;
        align-items: center;
        gap: 20px;
        /* padding: 20px; */
    }
    .dp {
        width: 70px;
        height: 70px;
        /* border-radius: 50%; */
        overflow: hidden;

        display: flex;
        align-items: center;
    }
    .dp img {
        width: 70px;
    }
    .name {
        /* font-size: 18px; */
        font-weight: 700;
    }
    .username {
        font-size: 14px;
        color: gray;
    }

    .stat {
        border-top: 1px solid #ddd;
        display: flex;
        padding: 10px;
        gap: 15px;
    }
    .flex {
        display: flex;
        gap: 5px;
        font-size: 12px;
    }
    .l {
        color: gray;
    }
    .value {
        font-weight: 500;
    }

    .profile_opts {
        border-top: 1px solid #ddd;
        display: grid;
        grid-template-columns: 50% 50%;
        align-items: center;
        color: var(--main);
        font-size: 14px;
        font-weight: 600;
        /* cursor: pointer; */
    }
    .profile_opt {
        padding: 10px;
        text-align: center;
        transition: .2s;
    }
    .profile_opt:focus, .profile_opt:hover {
        background-color: var(--trans);
    }


    .version {
        position: absolute;
        bottom: 0px;
        left: 0px;
        width: 100%;
        padding: 5px;
        box-sizing: border-box;
        text-align: center;
        font-size: 14px;
    }
</style>